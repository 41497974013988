import React from "react";
import { useSelector } from "react-redux";
import { TbLoader2 } from "react-icons/tb";
import { GiSevenPointedStar } from "react-icons/gi";
import { MdDelete } from "react-icons/md";
import { useState } from "react";
import { Link } from "react-router-dom";
import { FaStar } from "react-icons/fa";

const Product = ({
  id,
  subcategoryId,
  title,
  warranty,
  rating,
  reviews,
  price,
  description,
  url,
  isAdded,
  duration,
  handleProductClick,
  clickedId,
}) => {
  const { isLoadingCart } = useSelector((store) => store.cart);

  const handleProductUpdate = (id) => {
    if (!isLoadingCart) handleProductClick(id);
  };

  return (
    <div className="relative flex items-center justify-between h-[14.438rem] rounded-[1.25rem] min-w-[25.5rem] w-full mt-[3.65rem] hover:scale-105 transition ease-in-out border-[1px] border-[#0000004D]  pl-[2.25rem] pr-2">
      <div className="h-full flex flex-col gap-[1.75rem] items-center py-[2.25rem] justify-between ">
        {url && (
          <img src={url} alt="" className="min-w-[6.25rem] min-h-[6.25rem] " />
        )}
        <div className="text-center ">
          <div className=" text-[#00000099] capitalize">{title}</div>
          <div className="h-4 w-12 rounded-[0.25rem]"></div>
        </div>
      </div>

      {/* details */}

      <div className="w-max flex flex-col justify-between  pt-[1.75rem] pb-[2.8rem] h-full items-end">
        {/* reviews */}
        <div className="mr-[1.5rem]   w-max flex flex-col items-center gap-1 ">
          <div className="h-6 w-12 text-xs bg-[#EEBF00] flex items-center justify-center gap-1 rounded-md text-white">
            {rating} <FaStar className=" text-white w-3 h-3" />
          </div>
          <div className=" text-xs">
            {reviews + (reviews > 1 ? " reviews" : " review")}
          </div>
        </div>
        <div className="text-[#000000AD] flex flex-col items-center gap-1 ">
          <span>Start with ₹{price}</span>
          <div className="text-xs text-[#325D03E8] border-[1px] border-[#ACD3BA] py-2 w-[9.375rem] rounded-[0.625rem] text-center">
            {warranty + (warranty > 1 ? " months warranty" : " month warranty")}
          </div>
        </div>
      </div>
      <div
        className="absolute -bottom-4 flex justify-center text-sm  text-white font-semibold cursor-pointer w-[100%] right-0  h-[2.125rem]  "
        onClick={() => handleProductUpdate(id)}>
        {isAdded ? (
          <div className="flex items-center justify-center  h-full bg-primary-40 rounded-lg w-[9.75rem]">
            {isLoadingCart && id === clickedId ? (
              <TbLoader2 className=" text-xl animate-spin " />
            ) : (
              "Remove"
            )}
          </div>
        ) : (
          <div className="flex items-center justify-center h-full bg-primary-600 rounded-lg w-[9.75rem]">
            {isLoadingCart && id === clickedId ? (
              <TbLoader2 className="text-xl animate-spin" />
            ) : (
              "Add"
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default Product;
