import React from "react";
import CheckoutStatus from "../../CheckoutStatus";
import CheckoutSidebar from "../CheckoutSidebar";
import { useState } from "react";
import { postBooking } from "../../../features/Bookings/BookingSlice";
import PageLoader from "../../PageLoader";
import Completed from "../../Completed";
import { useEffect } from "react";

const Desktop = ({
  subCatgId,
  location,
  isLoadingBooking,
  mode,
  handleModeChange,
  handleConfirmBooking,
  handleDataChange,
  BookingDetails,
}) => {
  return (
    <div className="w-full ">
      {/* popups */}
      {isLoadingBooking && <PageLoader />}

      {/* {status === 201 && <Completed confirmed={confirmed} />} */}
      {/* Address */}

      <CheckoutStatus location={location} subCatgId={subCatgId} />
      <div className="flex w-8/12 m-auto min-w-max py-10">
        {/* cart details and address */}
        <div className="py-4 px-4 w-4/5  m-auto flex flex-col gap-4">
          <div className="flex w-5/6 mx-auto">
            <button
              className={
                "w-1/2 border border-primary-600 hover:border-[#a13974] p-2 " +
                (mode === "cash"
                  ? " bg-primary-600 text-white"
                  : " text-primary-600 ")
              }
              onClick={() => handleModeChange("cash")}>
              Cash On Delivery
            </button>
            <button
              className={
                "w-1/2 border border-primary-600 hover:border-[#a13974] p-2 " +
                (mode === "online"
                  ? " bg-primary-600 text-white"
                  : " text-primary-600 ")
              }
              onClick={() => handleModeChange("online")}>
              Online
            </button>
          </div>
          <div className=" w-5/6 m-auto flex items-center justify-center">
            <CheckoutSidebar
              location={location}
              subCatgId={subCatgId}
              handleDataChange={handleDataChange}
              bookingState={BookingDetails}
            />
          </div>
          <button
            className="text-xl  uppercase bg-primary-600 text-white w-5/6 m-auto p-2"
            onClick={handleConfirmBooking}
            disabled={isLoadingBooking}>
            confirm Booking
          </button>
        </div>
      </div>
    </div>
  );
};

export default Desktop;
