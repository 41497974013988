import React, { useEffect } from "react";
import { BsSearch } from "react-icons/bs";
import { RxCross2 } from "react-icons/rx";
import { useDispatch, useSelector } from "react-redux";
import ObjectLoader from "./ObjectLoader";
import {
  fetchNonExpiredCoupon,
  redeemCoupon,
} from "../features/coupons/CouponSlice";
import { toast } from "sonner";

const ApplyCoupon = ({ handleApplyCoupon }) => {
  const dispatch = useDispatch();
  const { totalAmount } = useSelector((state) => state.cart);

  const { redeem, couponList, isLoadingCoupon } = useSelector(
    (state) => state.coupon
  );
  const handleRedeem = async (couponId, couponDiscount, couponCode) => {
    if (totalAmount < couponDiscount) {
      toast.error("Coupon amount should be greater than total amount");
      return;
    }
    const resp = await dispatch(redeemCoupon({ couponId: couponId }));
    if (resp?.type?.indexOf("fulfilled") > -1) {
      toast.success("Coupon Applied", {
        onAutoClose: () =>
          handleApplyCoupon(couponId, couponCode, couponDiscount),
        duration: 500,
      });
    }
    console.log(resp);
  };
  useEffect(() => {
    dispatch(fetchNonExpiredCoupon());
  }, []);
  return (
    <div className="w-full h-screen top-0 left-0 fixed z-[100] flex items-center justify-center ">
      <div
        className="w-full h-full bg-[#00000058] absolute "
        onClick={handleApplyCoupon}></div>
      <div className="w-[28rem] h m-auto h-[32rem] bg-white rounded-xl relative max-md:absolute max-md:bottom-0 max-md:w-full popup">
        <div
          className="absolute -top-10 -right-1 bg-white p-2 rounded-full cursor-pointer max-md:hidden"
          onClick={handleApplyCoupon}>
          <RxCross2 />
        </div>
        <div className="w-full py-8 px-4 flex flex-col h-full max-md:px-1">
          <h1 className="w-full text-xl text-center">Apply Coupon</h1>
          <div className="w-full flex items-center gap-2 border border-primary-max rounded mt-4">
            <input
              type="text"
              className="flex-1 pl-4  outline-none"
              placeholder="Enter coupon code"
            />
            <div className="px-2 text-white uppercase bg-primary-600 h-full py-1">
              Apply
            </div>
          </div>
          {isLoadingCoupon ? (
            <div className="flex flex-1 items-center justify-center">
              <ObjectLoader />
            </div>
          ) : (
            <div className="flex-1 overflow-y-scroll no-scrollbar flex flex-col w-full gap-3 mt-4">
              {couponList?.map((item, index) => (
                <div
                  className="border border-primary-600 px-4 py-2 flex items-center"
                  key={index}
                  onClick={() =>
                    handleRedeem(
                      item?.id,
                      item?.couponDiscount,
                      item?.couponCode
                    )
                  }>
                  <div className="flex-1">
                    <div>{item?.couponName}</div>
                    <div
                      className="text-xs text-gray-600"
                      dangerouslySetInnerHTML={{
                        __html: item?.description,
                      }}></div>
                  </div>
                  <button className="uppercase border border-primary-600 px-2 py-1 max-md:text-xs">
                    {item?.couponCode}
                  </button>
                </div>
              ))}
              {!(couponList?.length > 0) && (
                <div className="border border-primary-600 px-4 py-2 flex items-center bg-gray-100 text-gray-500">
                  <span>No Coupon Found</span>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ApplyCoupon;
