import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { FaTag } from "react-icons/fa6";
import { BiSolidOffer } from "react-icons/bi";
import { useSelector } from "react-redux";
import ApplyCoupon from "../ApplyCoupon";
import { toast } from "sonner";
import {
  calculateCommission,
  calculateTax,
  finalServicePriceCalculator,
} from "../../utils/GlobalFunctions";

const CheckoutSidebar = ({
  subCatgId,
  location,
  bookingState,
  handleDataChange,
}) => {
  const navigate = useNavigate();
  const [isApplyingCoupon, setIsApplyingCoupon] = useState(false);
  const { totalAmount } = useSelector((store) => store.cart);

  const currentLocation = location?.pathname?.slice(0, -1);

  const locations = {
    "/check-out-details/cart/": 1,
    "/check-out-details/address/": 2,
    "/check-out-details/slot/": 3,
    "/check-out-details/payment/": 4,
  };

  const handlePalceOrder = () => {
    if (locations[currentLocation] === 1)
      navigate(`/check-out-details/address/${subCatgId}#blockBack`, {
        state: bookingState,
      });
    else if (locations[currentLocation] === 2) {
      if (bookingState?.address) {
        navigate(`/check-out-details/slot/${subCatgId}#blockBack`, {
          state: bookingState,
          replace: true,
        });
      } else {
        alert("Please select address!");
      }
    } else if (locations[currentLocation] === 3) {
      if (bookingState?.bookingSlot)
        navigate(`/check-out-details/payment/${subCatgId}#blockBack`, {
          state: bookingState,
          replace: true,
        });
      else {
        toast.dismiss();
        toast.warning("Please select a slot!");
      }
    }
  };
  console.log(bookingState);

  // Apply coupon
  const handleApplyCoupon = (couponId, couponCode, couponDiscount) => {
    if (couponCode !== "" && couponId > 0) {
      handleDataChange({
        ...bookingState,
        couponId: couponId,
        couponCode: couponCode,
        couponDiscount: couponDiscount,
      });
    }
    setIsApplyingCoupon(false);
  };
  return (
    <>
      {isApplyingCoupon && (
        <ApplyCoupon handleApplyCoupon={handleApplyCoupon} />
      )}
      <div className="sticky top-0 w-full p-4 flex flex-col gap-4">
        {/* popups */}
        {(locations[currentLocation] === 1 ||
          locations[currentLocation] === 4) && (
          <div className=" font-mono uppercase font-semibold text-gray-400 text-sm">
            {locations[currentLocation] === 1 ? "Coupons" : "Bank offers"}
          </div>
        )}
        {/*Apply cupons  */}
        {locations[currentLocation] === 1 ||
        locations[currentLocation] === 4 ? (
          <div className="border p-4 flex items-center rounded-sm mb-6">
            <div className="w-5/6 flex gap-4 items-center text-sm font-semibold ">
              <FaTag className="text-xl" />
              <div>{bookingState?.couponCode || "Apply Coupon"}</div>
            </div>
            <div
              className="border px-4 py-1 text-sm font-semibold uppercase border-primary-600 text-primary-600 cursor-pointer"
              onClick={() => setIsApplyingCoupon(true)}>
              {bookingState?.couponId ? "Applied" : "Apply"}
            </div>
          </div>
        ) : (
          // : locations[currentLocation] === 4 ? (
          //   <div className="border p-4 flex items-center rounded-sm mb-6">
          //     <div className="w-5/6 flex gap-4 items-center text-sm font-semibold ">
          //       <BiSolidOffer className="text-xl" />
          //       <div>Apply Offer</div>
          //     </div>
          //     <div className="border px-4 py-1 text-sm font-semibold uppercase border-primary-600 text-primary-600">
          //       Apply
          //     </div>
          //   </div>
          // )
          ""
        )}
        <div className=" font-mono uppercase font-semibold text-gray-400 text-sm ">
          Price Details
        </div>
        {/* Price breakup */}
        <div className="grid grid-cols-2 text-sm text-gray-500 w-full gap-2">
          <div>Total MRP</div>
          <div className="text-right">₹{totalAmount}</div>
          {/* <div>Discounts</div>
          <div className="text-right">-₹100</div> */}
          <div>Coupon discount</div>
          <div
            className="text-right text-primary-600 text-xs cursor-pointer"
            onClick={() => setIsApplyingCoupon(true)}>
            {parseInt(bookingState?.couponDiscount) > 0
              ? "-₹" + bookingState?.couponDiscount
              : "Apply Coupon"}
          </div>
          <div>Tax & Commission</div>
          <div className="text-right">
            ₹{calculateCommission(totalAmount) + calculateTax(totalAmount)}
          </div>
          <div className="font-bold text-[16px] text-gray-600 ">
            Total Amount
          </div>
          <div className="font-bold text-[16px] text-gray-600 text-right">
            ₹
            {finalServicePriceCalculator(
              totalAmount,
              parseInt(bookingState?.couponDiscount)
            )}
          </div>
        </div>
        {/* Proceed */}
        {locations[currentLocation] !== 4 && (
          <div
            className="text-lg font-semibold uppercase flex items-center justify-center bg-primary-600 text-white mt-4 py-1 cursor-pointer max-[720px]:hidden "
            onClick={handlePalceOrder}>
            {locations[currentLocation] === 1 ? "Place order" : "Continue"}
          </div>
        )}
      </div>
    </>
  );
};

export default CheckoutSidebar;
