import React from "react";
import CheckoutSidebar from "../CheckoutSidebar";
import { useNavigate } from "react-router-dom";
import CheckoutStatus from "../../CheckoutStatus";

const Mobile = ({
  subCatgId,
  location,
  isLoadingBooking,
  handleDataChange,
  mode,
  handleModeChange,
  handleConfirmBooking,
  BookingDetails,
}) => {
  const navigate = useNavigate();

  const handleNavigate = () => {};
  return (
    <div className="mb-40">
      <CheckoutStatus location={location} subCatgId={subCatgId} />

      <div className="py-4 px-4 mt-10   w-11/12 mx-auto  border-r flex flex-col gap-4">
        <div className="flex w-full mx-auto">
          <button
            className={
              "w-1/2 border border-primary-600 hover:border-[#a13974] p-2 " +
              (mode === "cash"
                ? " bg-primary-600 text-white"
                : " text-primary-600 ")
            }
            onClick={() => handleModeChange("cash")}>
            Cash On Delivery
          </button>
          <button
            className={
              "w-1/2 border border-primary-600 hover:border-[#a13974] p-2 " +
              (mode === "online"
                ? " bg-primary-600 text-white"
                : " text-primary-600 ")
            }
            onClick={() => handleModeChange("online")}>
            Online
          </button>
        </div>
      </div>
      <div className="w-11/12 mx-auto mt-4">
        <CheckoutSidebar
          location={location}
          subCatgId={subCatgId}
          bookingState={BookingDetails}
          handleDataChange={handleDataChange}
        />
      </div>

      <div className="w-full py-[0.5rem] text-center fixed bottom-0 left-0 z-10 bg-white top-shadow min-w-full">
        <div
          className="min-w-4/5 w-4/5 py-[0.5rem] text-center font-semibold text-white capitalize heading-gradient rounded-[3rem] mx-auto cursor-pointer"
          onClick={handleConfirmBooking}
          disabled={isLoadingBooking}>
          confirm Booking
        </div>
      </div>
    </div>
  );
};

export default Mobile;
