import { getUserFromSessionStorage } from "./sessionStorage";

export const addUserToLocalStorage = (user) => {
  localStorage.setItem("user", JSON.stringify(user));
};

export const removeUserFromLocalStorage = () => {
  localStorage.removeItem("user");
};
export const removeStoreFromLocalStorage = () => {
  localStorage.removeItem("root");
};

export const getUserFromLocalStorage = () => {
  const result = localStorage.getItem("user");
  const user = result ? JSON.parse(result) : null;
  if (user) {
    return user;
  } else {
    return getUserFromSessionStorage();
  }
};

//remove add get location from local storage

export const removeLocationFromLocalStorage = () => {
  localStorage.removeItem("location");
};

export const getLocationFromLocalStorage = () => {
  const result = localStorage.getItem("location");
  const location = result ? JSON.parse(result) : null;
  if (location) {
    return location;
  } else {
    return {
      lat: 12.91654,
      lon: 77.651947,
      address: "HSR Layout",
      city: "Bengaluru",
      subLocality: "Kormangla",
    };
  }
};

//remove add get location from local storage

export const addLocationToLocalStorage = (location) => {
  localStorage.setItem("location", JSON.stringify(location));
};
