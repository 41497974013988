import React from "react";

const CheckCircleIcon = () => {
  return (
    <img
      src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
      alt="Ex"
      className=" w-5"
    />
  );
};

export default CheckCircleIcon;
