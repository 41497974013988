import React from "react";
import { MdVerified } from "react-icons/md";
import { useDispatch } from "react-redux";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { motion } from "framer-motion";

const Completed = ({ confirmed }) => {
  const navigate = useNavigate();
  const location = useLocation();
  console.log(location);

  const handleNavigate = (path) => {
    navigate(`${path}`, { replace: true });
  };
  return (
    <div className="fixed top-0 bg-[#00000086] w-screen h-screen z-40 flex items-center justify-center max-sm:items-end">
      <div className="w-[400px] h-[400px] bg-white shadow-lg flex flex-col items-center justify-center relative popup max-sm:w-full max-sm:pb-10 max-sm:h-full">
        <div className="flex flex-col items-center rela">
          <motion.div
            initial={{ scale: 1.5, rotate: 180, opacity: 0.5 }}
            animate={{ rotate: 0, scale: 1, opacity: 1 }}
            transition={{
              type: "spring",
              stiffness: 260,
              damping: 20,
              duration: 0.75,
            }}>
            <MdVerified className="text-6xl text-[#7cc53d]" />
          </motion.div>

          <h1 className="font-bold text-xl text-[#66a430] capitalize mt-5">
            Order Confirmed
          </h1>
          <div className="mx-8 mt-4 text-center">
            Your order is confirmed.You will receive an order confermation mail
            soon.
          </div>
          <div className="mx-8 mt-2 text-center text-sm font-semibold">
            {location?.state?.paymentId}
          </div>
        </div>
        <div className="absolute bottom-0 h-1/6  w-full flex gap-2 items-center justify-center max-sm:bottom-5">
          <button
            onClick={() => handleNavigate("/")}
            className="px-2 py-2 border font-mono bg-[#ffffff] text-black uppercase text-sm w-36 text-center ">
            Continue Shoping
          </button>
          <button
            onClick={() => handleNavigate(`/booking-details/${confirmed}`)}
            className="px-2 py-2 border font-mono bg-primary-600 text-white uppercase text-sm w-36 text-center ">
            View Details
          </button>
        </div>
      </div>
    </div>
  );
};

export default Completed;
