import React, { useState, useEffect } from "react";
import { IoSettingsSharp } from "react-icons/io5";
import { IoIosConstruct, IoIosLaptop } from "react-icons/io";
import { RiFridgeFill } from "react-icons/ri";
import { TbLoader2 } from "react-icons/tb";

const PageLoader = () => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % 4);
    }, 600);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="fixed w-screen h-screen bg-[#000000a1] z-50 flex flex-col justify-center items-center  gap-6 top-0">
      {/* <div className="  text-white flex gap-2">
        {currentIndex === 0 && <IoSettingsSharp className=" h-20 w-20  " />}
        {currentIndex === 1 && <IoIosConstruct className=" h-20 w-20" />}
        {currentIndex === 2 && <IoIosLaptop className=" h-20 w-20" />}
        {currentIndex === 3 && <RiFridgeFill className=" h-20 w-20" />}
      </div> */}
      <div className="  text-white flex gap-2">
        <IoSettingsSharp
          className={` h-20 w-20 block transform transition-all duration-50 ease-out ${
            currentIndex === 0 ? " scale-100" : "scale-0 absolute"
          } `}
        />
        <IoIosConstruct
          className={` h-20 w-20 block transform transition-all duration-50 ease-out ${
            currentIndex === 1 ? " scale-100" : "scale-0 absolute"
          } `}
        />
        <IoIosLaptop
          className={` h-20 w-20 block transform transition-all duration-50 ease-out ${
            currentIndex === 2 ? " scale-100" : "scale-0 absolute"
          } `}
        />
        <RiFridgeFill
          className={` h-20 w-20 block transform transition-all duration-50 ease-out ${
            currentIndex === 3 ? " scale-100" : "scale-0 absolute"
          } `}
        />
      </div>
      <div className="text-white flex text-xl items-center">
        <TbLoader2 className="animate-spin  mr-2 " />
        Processing..
        <span className="animate-pulse">..</span>
      </div>

      {/* <div className="flex gap-6">
        <div className="bg-white w-3 h-3 rounded-full"></div>
        <div className="bg-white w-3 h-3 rounded-full"></div>
        <div className="bg-white w-3 h-3 rounded-full"></div>
      </div> */}
    </div>
  );
};

export default PageLoader;
