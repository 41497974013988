import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  forgotPassword,
  generateOtp,
  loginUser,
} from "../features/user/UserSlice";
import { Link, useNavigate } from "react-router-dom";
import { empluxIcon, logoEmplux, signin_banner } from "../utils/Urls";
import { Encode } from "../utils/GlobalFunctions";
import { Toaster } from "sonner";

const initialState = {
  phoneNo: "",
  rememberMe: true,
};

const OtpLogin = () => {
  const [values, setValues] = useState(initialState);
  const { user, isLoading } = useSelector((store) => store.user);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleChange = (e) => {
    const name = e.target.name;
    const value = e.target.value;

    setValues({ ...values, [name]: value?.slice(0, 10) });
  };
  const onSubmit = async (e) => {
    e.preventDefault();
    const { phoneNo } = values;
    const number = "+91" + phoneNo;

    const resp = await dispatch(generateOtp({ phoneNo: number }));

    if (resp?.type?.indexOf("fulfilled") > -1) {
      const encodedNumber = Encode(number);
      navigate(`/login/verify-otp/${encodedNumber}`, {
        state: { rememberMe: values?.rememberMe },
      });
    }
  };
  // remember me
  const handleRememberMe = (e) => {
    const isChecked = e.target.checked;
    setValues({ ...values, rememberMe: isChecked });
  };

  const toggleMember = () => {
    setTimeout(() => {
      navigate("/register");
    }, 10);
  };
  return (
    <div className="login-container flex flex-col lg:flex-row h-screen">
      <Toaster richColors position="top-center" />
      <div className="image-section relative w-full lg:w-1/2">
        <img
          src={signin_banner}
          alt="Login"
          className="object-cover h-full w-full"
        />
        {/* <div className="absolute top-0 left-0 right-0 bottom-0 flex flex-col items-center justify-center text-center">
          <h2 className="text-4xl text-white font-bold mb-4">
            Welcome to ClapUrban
          </h2>
          <p className="text-medium text-white">
            simply dummy text of the printing and typesetting industry
          </p>
        </div> */}
      </div>

      <div className="w-full lg:w-1/2 mt-20">
        <Link
          to={"/"}
          className="flex w-full items-center justify-center  gap-2">
          <img
            src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
            alt=""
            className="w-20"
          />
          <h1 className=" p-1  text-4xl font-bold uppercase text-primary-600  ">
            EMPLUX
          </h1>
        </Link>

        <div className="w-full p-6 mx-auto lg:max-w-xl mt-10">
          <div className="form-section">
            <form onSubmit={onSubmit}>
              <div className="mb-2 mt-5 ">
                {/* Email field */}
                <input
                  type="text"
                  name="phoneNo"
                  placeholder="Mobile Number"
                  value={values?.phoneNo}
                  onChange={handleChange}
                  className="block w-full px-4 py-2 mt-2 text-neutral-800 bg-stone-100 border roundued-md focus:border-neutral-800 focus:ring-indigo-600 focus:outline-none focus:ring focus:ring-opacity-40"
                  required
                />
              </div>

              <div className="flex justify-between">
                <p className="mt-3 text-xs font-light text-start text-gray-700">
                  <label className="flex items-center">
                    <input
                      type="checkbox"
                      className="form-checkbox h-4 w-4 text-gray-600 transition duration-150 ease-in-out"
                      checked={values?.rememberMe}
                      onChange={handleRememberMe}
                    />
                    <span className="ml-2 text-sm font-medium text-gray-700">
                      Remember me
                    </span>
                  </label>
                </p>
                <p className="mt-3 text-xs font-light text-start text-gray-700">
                  <Link
                    to={"/password-login"}
                    className="ml-2 text-sm font-medium text-gray-700 cursor-pointer hover:underline ">
                    Login with Password
                  </Link>
                </p>
              </div>

              <div className="mt-6">
                <button
                  disabled={isLoading}
                  className="w-full px-4 py-2 tracking-wide text-white transition-colors duration-200 transform bg-neutral-800 rounded-md hover:bg-neutral-600 focus:outline-none focus:bg-purple-600">
                  {isLoading ? "Loading..." : "Generate OTP"}
                </button>
              </div>

              <p className="mt-8 text-xs font-light text-center text-gray-700  hidden">
                Don't have an account?{""}
                <button
                  type="button"
                  className="font-medium text-neutral-800 outline-white hover:underline ml-2"
                  onClick={toggleMember}>
                  <strong>Sign Up</strong>
                </button>
              </p>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OtpLogin;
