import React, { useEffect, useState } from "react";
import { RxCross2 } from "react-icons/rx";
import { TfiTarget } from "react-icons/tfi";
import { toast } from "sonner";
import { pinIcon } from "../Images";
import { FaLocationArrow } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  checkMyLocation,
  clearAutofillLocations,
  getAddressFromLatLon,
  getAutoFillLocations,
} from "../features/location/LocationSlice";

const LocationInputPopup = ({ handlePopup }) => {
  const dispatch = useDispatch();
  // Locataion
  const { isLoadingLocation, autoFillLocations, userLocation } = useSelector(
    (state) => state.location
  );
  const [locationText, setLocationText] = useState();

  const [latLon, setLatLon] = React.useState({
    lat: null,
    lon: null,
  });

  const getCurrentLocation = () => {
    if (navigator?.geolocation) {
      navigator.geolocation?.getCurrentPosition(
        (position) => {
          setLatLon({
            lat: position.coords.latitude,
            lon: position.coords.longitude,
          });
        },
        (error) => {
          toast.dismiss();

          switch (error.code) {
            case error.PERMISSION_DENIED:
              toast.warning("Permission denied. Please allow location.");
              break;
            case error.POSITION_UNAVAILABLE:
              toast.warning("Location information is unavailable.");
              break;
            case error.TIMEOUT:
              toast.warning("The request to get your location timed out.");
              break;
            default:
              toast.warning("An unknown error occurred.");
          }
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleLocationTextChange = (e) => {
    const value = e?.target?.value;
    setLocationText(value);

    if (value?.length >= 3) {
      dispatch(getAutoFillLocations(value));
    }
  };
  const handleLocationSelected = async (location) => {
    setLocationText(location?.address);
    const resp = await dispatch(
      checkMyLocation({ location: location, isStrict: true })
    );
    if (resp?.type?.indexOf("fulfilled") > -1) {
      dispatch(clearAutofillLocations());
      handlePopup();
    }
  };
  useEffect(() => {
    const checkLocation = async () => {
      const resp = await dispatch(
        getAddressFromLatLon({
          lat: latLon?.lat,
          lon: latLon?.lon,
        })
      );

      await dispatch(
        checkMyLocation({ location: resp?.payload, isStrict: true })
      );
    };
    if (latLon?.lat && latLon?.lon) {
      checkLocation();
    }
  }, [latLon]);

  return (
    <div className="w-full h-full top-0 left-0 fixed z-[100] flex items-center justify-center ">
      <div
        className="w-full h-full bg-[#00000058] absolute "
        onClick={handlePopup}></div>
      <div className="w-[28rem] m-auto h-[18rem] bg-white rounded-xl relative max-md:absolute max-md:bottom-0 max-md:w-full popup px-10 py-16 flex flex-col items-center justify-between">
        <div
          className="absolute -top-10 -right-1 bg-white p-2 rounded-full cursor-pointer max-md:hidden"
          onClick={handlePopup}>
          <RxCross2 />
        </div>
        <div className="w-11/12 mx-auto flex gap-2 items-center border-b pb-2 relative">
          <FaLocationArrow className="text-3xl text-primary-70 " />
          <input
            type="text"
            onChange={handleLocationTextChange}
            value={locationText}
            className="outline-none  w-full px-2"
            placeholder="Search for area, street name.."
          />
          {/* //locations list */}
          <div className="absolute w-full top-[110%] bg-white p-4 flex flex-col gap-4 rounded-b-10 border-b border-x border-gray-200 text-gray-500 font-medium text-sm z-10">
            {autoFillLocations?.map((location, index) => (
              <div
                key={index}
                className="flex  gap-4 cursor-pointer  "
                onClick={() => {
                  handleLocationSelected(location);
                }}>
                <FaLocationArrow className="text-md text-primary-70 mt-1  " />
                <div className="border-b border-gray-300 flex-1 h-full pb-3  ">
                  {location?.mainText}
                </div>
              </div>
            ))}
          </div>
        </div>
        <button
          className=" flex items-center gap-4 border rounded p-3 w-full justify-center font-semibold tracking-wider uppercase border-[#00000049] "
          onClick={getCurrentLocation}>
          <TfiTarget className="text-2xl" />
          get currect location
        </button>
      </div>
    </div>
  );
};

export default LocationInputPopup;
