import { cryptoSecretKey } from "./Constants";
import { toast } from "sonner";
import { CheckCircleIcon, ErrorIcon, InfoIcon } from "../components/Icons"; // Import your custom icons

export const formatFormDate = (date) => {
  if (date === null) {
    return "";
  }
  const d = new Date(date);
  const year = d.getFullYear();
  const month = `0${d.getMonth() + 1}`.slice(-2); // Adding leading zero
  const day = `0${d.getDate()}`.slice(-2); // Adding leading zero
  return `${year}-${month}-${day}`;
};

export const findEmptyValueKey = (obj) => {
  console.log("obj", obj);
  for (const [key, value] of Object.entries(obj)) {
    if (value === "" || value === null || value === undefined) {
      return key;
    }
  }
  return null; // or you can return an appropriate value or message
};

const CryptoJS = require("crypto-js");

// Function to encode (encrypt) a message
export const Encode = (message) => {
  const ciphertext = CryptoJS.AES.encrypt(message, cryptoSecretKey).toString();

  // Convert to URL-safe Base64
  const urlSafeCiphertext = ciphertext
    .replace(/\+/g, "-")
    .replace(/\//g, "_")
    .replace(/=+$/, ""); // Remove any trailing '=' characters

  return urlSafeCiphertext;
};

// Function to decode (decrypt) a message
export const Decode = (ciphertext) => {
  // Convert from URL-safe Base64 back to normal Base64
  const normalCiphertext = ciphertext.replace(/-/g, "+").replace(/_/g, "/");

  const bytes = CryptoJS.AES.decrypt(normalCiphertext, cryptoSecretKey);
  return bytes.toString(CryptoJS.enc.Utf8);
};

export function formatTime(seconds) {
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  // Pad the minutes and seconds with leading zeros if they are less than 10
  const paddedMinutes = String(minutes).padStart(2, "0");
  const paddedSeconds = String(remainingSeconds).padStart(2, "0");

  return `${paddedMinutes}:${paddedSeconds}`;
}

export function capitalizeSentence(sentence) {
  return sentence
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
}

export const calculateTax = (amount) => {
  const tax = Math.ceil(amount * 0.05);
  return tax;
};

export const calculateCommission = (amount) => {
  const commission = Math.ceil(amount * 0.02);
  return commission;
};

// calculate final price after adding tax and commissin and removing discount
export const finalServicePriceCalculator = (amount, discount) => {
  const tax = calculateTax(amount);
  const bookingCommission = calculateCommission(amount);
  const totalAmount = amount + tax + bookingCommission;
  const finalAmount = Math.ceil(totalAmount - discount);
  return finalAmount;
};

const icons = {
  success: <CheckCircleIcon />,
  error: <ErrorIcon />,
  info: <InfoIcon />,
};

export function showNotification(type, message) {
  //info,error,success
  toast(message, {
    icon: icons[type] || icons.info,
  });
}
