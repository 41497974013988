import React from "react";

const InfoIcon = () => {
  return (
    <img
      src="https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png"
      alt="Ex"
      className="h-10 w-10l"
    />
  );
};

export default InfoIcon;
