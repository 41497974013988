import React from "react";
import Desktop from "../../../components/CheckOutDetails/CheckoutSlot/Desktop";
import { useLocation, useParams } from "react-router-dom";
import { useState } from "react";
import { useEffect } from "react";
import Mobile from "../../../components/CheckOutDetails/CheckoutSlot/Mobile";

const CheckoutSlot = () => {
  const location = useLocation();

  const [bookingState, setBookingState] = useState({
    address: location?.state?.address,
    categoryName: location?.state?.categoryName,
    subCategoryName: location?.state?.subCategoryName,
    bookingSlot: location?.state?.bookingSlot,
    amount: location?.state?.amount,
    paymentMode: location?.state?.paymentMode,
    couponId: location?.state?.couponId,
    couponCode: location?.state?.couponCode,
    couponDiscount: location?.state?.couponDiscount,
  });
  const [selectedDay, setSelectedDay] = useState(-1);
  const [selectedHour, setSelectedHour] = useState(null);

  const thirdDate = new Date();
  const hourNow = thirdDate.getHours();
  console.log(hourNow);
  thirdDate.setDate(thirdDate.getDate() + 2);

  const handleDaySelect = (day) => {
    if (day !== selectedDay) {
      setSelectedHour(null);
      handleDataChange({ ...bookingState, bookingSlot: null });
    }
    setSelectedDay(day - 1);
  };
  const handleTimeSelect = (time) => {
    console.log(time);
    if (hourNow + 1 >= time && selectedDay === 0) return;
    setSelectedHour(time);
    const date = new Date();
    date.setDate(date.getDate() + selectedDay);
    date.setHours(time, 0, 0, 0);
    console.log(date.toTimeString());
    handleDataChange({ ...bookingState, bookingSlot: date.toISOString() });
  };

  const handleDataChange = (data) => {
    setBookingState(data);
  };

  //Viewport width
  const [isDesktop, setDesktop] = useState(window.innerWidth > 720);
  const updateMedia = () => {
    console.log(window.innerWidth);
    setDesktop(window.innerWidth > 720);
  };
  useEffect(() => {
    window.addEventListener("resize", updateMedia);
    return () => window.removeEventListener("resize", updateMedia);
  });

  const { subCatgId } = useParams();
  useEffect(() => {
    window.scrollTo(0, 0);
  });

  return (
    <>
      {isDesktop ? (
        <Desktop
          subCatgId={subCatgId}
          location={location}
          bookingState={bookingState}
          handleDataChange={handleDataChange}
          selectedDay={selectedDay}
          handleDaySelect={handleDaySelect}
          thirdDate={thirdDate}
          hourNow={hourNow}
          selectedHour={selectedHour}
          handleTimeSelect={handleTimeSelect}
        />
      ) : (
        <Mobile
          subCatgId={subCatgId}
          location={location}
          bookingState={bookingState}
          handleDataChange={handleDataChange}
          selectedDay={selectedDay}
          handleDaySelect={handleDaySelect}
          thirdDate={thirdDate}
          hourNow={hourNow}
          selectedHour={selectedHour}
          handleTimeSelect={handleTimeSelect}
        />
      )}
    </>
  );
};

export default CheckoutSlot;
