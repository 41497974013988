import Product from "../Product";
import React, { useState } from "react";
import { FaClipboardList } from "react-icons/fa";
import { FiStar } from "react-icons/fi";
import { BsChevronDoubleDown } from "react-icons/bs";
import { AiOutlineDown, AiOutlineUp } from "react-icons/ai";
import Cart from "../Cart";
import { useSelector } from "react-redux";
import { home_banner_6, mobile_home_banner_4 } from "../../utils/Urls";
import ProductAndroid from "../ProductAndroid";
import CartMobile from "../CartMobile";
import { Link } from "react-router-dom";
import { Encode } from "../../utils/GlobalFunctions";

const Mobile = ({
  subCatgId,
  issuesList,
  subCatg,
  handleProductClick,
  clickedId,
}) => {
  const [isOpenFaqs, setOpen] = useState(false);
  const [isOpenQuestions, setOpenQuestions] = useState(false);
  const { cartItems } = useSelector((state) => state.cart);
  const handelOpenClick = () => {
    setOpen((isOpenFaqs) => !isOpenFaqs);
  };

  const handelQuestionClick = () => {
    setOpenQuestions((isOpenQuestions) => !isOpenQuestions);
  };
  return (
    <div className="w-full h-fit mb-10 pt-[0.5rem] overflow-hidden px-4">
      {/* popups */}
      <CartMobile location={"Services"} />
      {/* Head */}
      <div className="flex justify-between items-center pb-4 ">
        <div className="text-xl font-bold mb-3  px-2 ">
          {subCatg?.name}
          <div className="flex gap-2 items-center text-xs  font-normal mt-2 ">
            <span className="flex bg-[#eebf00] text-white rounded-md items-center gap-1  text-xs px-2 py-1">
              {subCatg?.rating} <FiStar />
            </span>
            <span className="text-[#EEBF00] uppercase ">
              {subCatg?.review} bookings
            </span>
          </div>
        </div>
        <span className="">
          <Link
            to={`/service/rate-chart/${Encode(subCatgId)}`}
            className="flex flex-col items-center float-right hover:scale-105 delay-50 transition ease-in-out duration-200 hover:shadow-sm p-2 ">
            <FaClipboardList className="m-auto text-2xl " />
            <span className="capitalize font-thin text-sm font-mono">rate</span>
          </Link>
        </span>
      </div>

      <img
        src={subCatg?.smallBannerM || subCatg?.thumbnailUrls || home_banner_6}
        alt=""
        className=" my-1 object-fit  w-full object-cover"
      />

      <div className=" flex justify-center  mb-10 gap-2 flex-col items-center">
        <div className="flex flex-col gap-2 items-center justify-center py-3 w-11/12">
          {issuesList?.map((element, index) => {
            const {
              id,
              issueName,
              issueReview,
              issueRating,
              issueDuration,
              issuePrice,
              issueDescription,
              appIssueIconUrl,
              issueIconUrl,
            } = element;

            return (
              <ProductAndroid
                key={index}
                id={id}
                subcategoryId={subCatgId}
                title={issueName}
                warranty={6}
                rating={issueRating}
                reviews={issueReview}
                price={issuePrice}
                description={issueDescription}
                url={appIssueIconUrl || issueIconUrl}
                isAdded={cartItems?.find(
                  (item) => item?.issueName == issueName
                )}
                duration={issueDuration}
                clickedId={clickedId}
                handleProductClick={handleProductClick}
              />
            );
          })}
          {/* proceed */}
          {/* {cartItems?.length > 0 && (
            <Link
              to={`/check-out-details/cart/${subCatgId}`}
              className="sticky bottom-10 left-1/2 transform -translate-x-1/2 uppercase font-medium px-4 py-1 bg-primary-600 rounded-lg z-10 text-white tracking-wider  popup w-1/2">
              Proceed to checkout
            </Link>
          )} */}
        </div>
      </div>
    </div>
  );
};

export default Mobile;
