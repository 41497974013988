import React from "react";
import { IoCall, IoLogoWhatsapp } from "react-icons/io5";
import { phoneNumber, whatsappNumber } from "../../../utils/Constants";

const Desktop = () => {
  return (
    <main className="min-h-screen ">
      <div className="w-[90%] mt-10 flex  mx-auto gap-5 text-gray-600">
        <a
          href={`tel:${phoneNumber}`}
          target="_blank"
          className="w-40 flex flex-col gap-5 items-center py-4 bg-gray-100 rounded-xl border">
          <IoCall className="text-7xl" />
          <h1 className="text-xl uppercase font-bold">Call</h1>
        </a>
        <a
          href={`https://wa.me/${whatsappNumber}?text=Hi%2C%20I%20want%20to%20know%20more%20about%20EMPLUX.`}
          target="_blank"
          className="w-40 flex flex-col gap-5 items-center py-4 bg-gray-100 rounded-xl border">
          <IoLogoWhatsapp className="text-7xl" />
          <h1 className="text-xl uppercase font-bold">CHAT</h1>
        </a>
      </div>
    </main>
  );
};

export default Desktop;
