import { Navigate, useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

const ProtectedRoute = ({ children }) => {
  const location = useLocation();
  const path = location?.pathname;

  const { user } = useSelector((store) => store.user);

  if (!user) {
    return <Navigate to="/login" state={{ path: path }} />;
  }
  return children;
};
export default ProtectedRoute;
