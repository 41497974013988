// useNotifications.js
import { useEffect } from "react";
import { getUserFromLocalStorage } from "../../utils/localStorage";
import { toast } from "sonner";

const useNotifications = () => {
  useEffect(() => {
    const requestNotificationPermission = async () => {
      if (!("Notification" in window)) {
        console.warn("This browser does not support desktop notifications.");
        return false;
      }

      if (Notification.permission === "granted") {
        return true;
      }

      if (Notification.permission !== "denied") {
        const permission = await Notification.requestPermission();
        return permission === "granted";
      }

      return false;
    };

    const showNotification = (message) => {
      if (Notification.permission === "granted") {
        console.log("Noti");
        new Notification("New Notification", {
          body: message,
          icon: "https://clapurban.blr1.digitaloceanspaces.com/1713083984695_EMPLUX-removebg-preview (1).png", // Optional: Replace with your icon path
        });
      } else {
        toast(message, {
          autoClose: 5000,
        });
      }
    };

    const initializeNotifications = async () => {
      const hasPermission = await requestNotificationPermission();
      const user = getUserFromLocalStorage();

      const eventSource = new EventSource(
        `${process.env.REACT_APP_NOTIFICATION_BASE_URL}api/v1/notifications/${user?.userId}`,
        {
          headers: { Authorization: `Bearer ${user?.accessToken}` },
        }
      );

      eventSource.onmessage = (event) => {
        const notification = JSON.parse(event.data);
        showNotification(notification?.msg);
      };

      eventSource.addEventListener("emplux", (event) => {
        const notification = JSON.parse(event.data);
        showNotification(notification?.msg);
      });

      return () => {
        /////////////////////////////
        eventSource.close();
      };
    };

    initializeNotifications();
  }, []);
};

export default useNotifications;
