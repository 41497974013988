import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  addLocationToLocalStorage,
  getLocationFromLocalStorage,
  getUserFromLocalStorage,
} from "../../utils/localStorage";
import customFetch, { krutrimFetch } from "../../utils/axios";
import { toast } from "sonner";
import axios from "axios";
import { showNotification } from "../../utils/GlobalFunctions";

const initialState = {
  isLoadingLocation: false,
  autoFillLocations: [],
  userLocation: {
    address: getLocationFromLocalStorage(),
  },
};

//post lot long for availability
export const checkMyLocation = createAsyncThunk(
  "home/checkMyLocation",
  async ({ location, isStrict = false }, thunkAPI) => {
    console.log(location);
    try {
      console.log(location);
      const resp = await customFetch.get("/api/map/geolocation", {
        params: {
          address: location?.address,
        },
      });

      return { data: resp?.data, location: location, isStrict: isStrict };
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

//get locations list
export const getAddressFromLatLon = createAsyncThunk(
  "home/getLocations",
  async ({ lat, lon }, thunkAPI) => {
    const API_KEY = process.env.REACT_APP_OLA_MAPS_API_KEY;
    try {
      const resp = await krutrimFetch?.get(
        `/places/v1/reverse-geocode?latlng=${12.972939}%2C${77.6279118}&api_key=${API_KEY}`
      );
      const result = resp?.data?.results[0];
      const formatted_address = result?.formatted_address;
      const address_component_sub_loc = result?.address_components?.find(
        (elem) => elem.types[0] === "sublocality"
      );
      const subLocality = address_component_sub_loc?.short_name;
      const address_component_loc = result?.address_components?.find(
        (elem) => elem.types[0] === "locality"
      );
      const locality = address_component_loc?.short_name;
      return {
        address: formatted_address,
        subLocality: subLocality,
        locality: locality,
        lat: lat,
        lon: lon,
      };
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);
// get location autocomplete
export const getAutoFillLocations = createAsyncThunk(
  "home/getAutoFillLocations",
  async (query, thunkAPI) => {
    const API_KEY = process.env.REACT_APP_OLA_MAPS_API_KEY;
    const currentLocation = getLocationFromLocalStorage();
    try {
      const resp = await krutrimFetch?.get(
        `places/v1/autocomplete?input=${query}&location=${currentLocation?.lat}%2C${currentLocation?.lon}&api_key=${API_KEY}`
      );
      console.log(resp?.data);
      return resp?.data?.predictions;
    } catch (error) {
      return thunkAPI.rejectWithValue(error?.response?.msg);
    }
  }
);

const LocationSlice = createSlice({
  name: "location",
  initialState,
  reducers: {
    setLoadingLocation: (state, { payload }) => {
      state.isLoadingLocation = payload;
    },
    clearAutofillLocations: (state) => {
      state.autoFillLocations = [];
    },
  },
  extraReducers: (builder) => {
    builder

      .addCase(checkMyLocation.pending, (state) => {
        state.isLoadingLocation = true;
      })
      .addCase(checkMyLocation.fulfilled, (state, { payload }) => {
        state.isLoadingLocation = false;
        if (payload?.data?.res) {
          addLocationToLocalStorage(payload?.location);
          state.userLocation = {
            address: payload?.location,
          };
        } else {
          if (payload?.isStrict) {
            toast.dismiss();
            showNotification(
              "success",
              "We are currently unavailable at your location! Please choose another location."
            );
          }
        }
      })
      .addCase(checkMyLocation.rejected, (state) => {
        state.isLoadingLocation = false;
      })
      .addCase(getAutoFillLocations.pending, (state) => {
        state.isLoadingLocation = true;
      })
      .addCase(getAutoFillLocations.fulfilled, (state, { payload }) => {
        //filter lat lon and secondary text from payload
        let tempLocations = [];
        for (let index = 0; index < payload?.length; index++) {
          tempLocations.push({
            address: payload[index]?.description,
            lat: payload[index]?.geometry?.location?.lat,
            lon: payload[index]?.geometry?.location?.lng,
            mainText: payload[index]?.structured_formatting?.main_text,
          });
          console.log(payload[index]);
        }
        state.autoFillLocations = tempLocations;
        state.isLoadingLocation = false;
      })
      .addCase(getAutoFillLocations.rejected, (state) => {
        state.isLoadingLocation = false;
      });
  },
});
export const { setLoadingLocation, clearAutofillLocations } =
  LocationSlice.actions;

export default LocationSlice.reducer;
